export default ((StudioDoorId,CompanyBranchId,DoorIp,DoorPort, DoorName,DurationBeforeStartDate,DurationAfterStartDate,DurationDoorOpen,IsActive) => ({
    StudioDoorId,StudioDoorId,
    CompanyBranchId:CompanyBranchId,
    DoorIp:DoorIp,
    DoorPort:DoorPort,
    DoorName:DoorName,
    DurationBeforeStartDate:DurationBeforeStartDate,
    DurationAfterStartDate:DurationAfterStartDate,
    DurationDoorOpen:DurationDoorOpen,
    IsActive:IsActive
}));