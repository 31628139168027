<template>
  <b-card>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="6" sm="12">
          <b-form-group :label="$t('Door Name')" label-for="DoorName">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="ItalicIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="DoorName"
                name="DoorName"
                type="text"
                placeholder="Kapı Adını giriniz"
                v-model="studioDoorCreateModel.DoorName"
                @blur="$v.studioDoorCreateModel.DoorName.$touch()"
              />
            </b-input-group>
            <small
              v-if="isSubmit && !$v.studioDoorCreateModel.DoorName.required"
              class="form-text text-danger"
              >{{ $t("This field is required") }}</small
            >
          </b-form-group>
        </b-col>

        <!-- Studio DoorCreate -->

        <b-col md="6" sm="12">
          <b-form-group :label="$t('Ip Number')" label-for="DoorIp">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="ItalicIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="DoorIp"
                name="DoorIp"
                type="text"
                placeholder="Ip Adresini Giriniz"
                v-model="studioDoorCreateModel.DoorIp"
                @blur="$v.studioDoorCreateModel.DoorIp.$touch()"
              />
            </b-input-group>
            <small
              v-if="isSubmit && !$v.studioDoorCreateModel.DoorIp.required"
              class="form-text text-danger"
              >{{ $t("This field is required") }}</small
            >
          </b-form-group>
        </b-col>

        <b-col md="6" sm="12">
          <b-form-group :label="$t('Port Number')" label-for="DoorPort">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="ItalicIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="DoorPort"
                name="DoorPort"
                type="number"
                placeholder="Port Numarasını Giriniz"
                v-model="studioDoorCreateModel.DoorPort"
                @blur="$v.studioDoorCreateModel.DoorPort.$touch()"
              />
            </b-input-group>
            <small
              v-if="isSubmit && !$v.studioDoorCreateModel.DoorPort.required"
              class="form-text text-danger"
              >{{ $t("This field is required") }}</small
            >
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            :label="$t('Duration Door Open')"
            label-for="DurationDoorOpen"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="ItalicIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="DurationDoorOpen"
                name="DurationDoorOpen"
                type="number"
                placeholder="Açılan Kapının ne kadar süre açık kalacağı zamanı giriniz (sn)"
                v-model="studioDoorCreateModel.DurationDoorOpen"
                @blur="$v.studioDoorCreateModel.DurationDoorOpen.$touch()"
              />
            </b-input-group>
            <small
              v-if="
                isSubmit && !$v.studioDoorCreateModel.DurationDoorOpen.required
              "
              class="form-text text-danger"
              >{{ $t("This field is required") }}</small
            >
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            :label="$t('Duration Before Start Date')"
            label-for="DurationBeforeStartDate"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="ItalicIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="DurationBeforeStartDate"
                name="DurationBeforeStartDate"
                type="number"
                placeholder="Kapının randevu saatinden önce açılabilceği zamanı giriniz.  (sn)"
                v-model="studioDoorCreateModel.DurationBeforeStartDate"
                @blur="
                  $v.studioDoorCreateModel.DurationBeforeStartDate.$touch()
                "
              />
            </b-input-group>
            <small
              v-if="
                isSubmit &&
                  !$v.studioDoorCreateModel.DurationBeforeStartDate.required
              "
              class="form-text text-danger"
              >{{ $t("This field is required") }}</small
            >
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            :label="$t('Duration After Start Date')"
            label-for="DurationAfterStartDate"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="ItalicIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="DurationAfterStartDate"
                name="DurationAfterStartDate"
                type="number"
                placeholder="Kapının randevu saatinden sonra açılabilceği zamanı giriniz.  (sn)"
                v-model="studioDoorCreateModel.DurationAfterStartDate"
                @blur="$v.studioDoorCreateModel.DurationAfterStartDate.$touch()"
              />
            </b-input-group>
            <small
              v-if="
                isSubmit &&
                  !$v.studioDoorCreateModel.DurationAfterStartDate.required
              "
              class="form-text text-danger"
              >{{ $t("This field is required") }}</small
            >
          </b-form-group>
        </b-col>

        <b-col md="6" sm="12">
          <b-form-group>
            <b-input-group class="input-group-merge">
              <div>
                <b-form-checkbox
                  name="IsActive"
                  id="IsActive"
                  v-model="studioDoorCreateModel.IsActive"
                >
                  <span style="padding-left:10px;">{{
                    $t("Activate the door")
                  }}</span>
                </b-form-checkbox>
              </div>
            </b-input-group>
            <!-- <small v-if="isSubmit && !$v.studioDoorCreateModel.IsActive.required" class="form-text text-danger" >{{$t("This field is required")}}</small> -->
          </b-form-group>
        </b-col>

        <!--  -->
        <b-col cmd="6" sm="12" class="right">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            type="reset"
            variant="outline-primary"
            class="mr-1"
            :to="{ name: 'studio_list' }"
          >
            {{ $t("Cancel") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            @click="create"
          >
            {{ $t("Save") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormTextarea,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import studioDoorCreateModel from "@/models/StudioModels/studioDoorCreateModel";

import { required, numeric, maxLength, email } from "vuelidate/lib/validators";

export default {
  directives: {
    Ripple,
  },
  components: {
    studioDoorCreateModel,
    BButton,
    Cleave,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BCardText,
    vSelect,
    mapGetters,
    mapActions,
    BFormTextarea,
    BFormRadio,
  },
  props: {},

  data() {
    return {
      studioDoorCreateModel: studioDoorCreateModel,
      dataloaded: false,
      selectedIndex: 0,
      isSubmit: false,
    };
  },
  validations: {
    studioDoorCreateModel: {
      StudioDoorId: {
        required: required,
      },
      CompanyBranchId: {
        required: required,
      },
      DoorIp: {
        required: required,
      },
      DoorPort: {
        required: required,
        numeric: numeric,
      },
      DoorName: {
        required: required,
      },
      DurationBeforeStartDate: {
        required: required,
        numeric: numeric,
      },
      DurationAfterStartDate: {
        required: required,
        numeric: numeric,
      },
      DurationDoorOpen: {
        required: required,
        numeric: numeric,
      },
    },
  },
  methods: {
    getStudioDoor() {
      this.$database.StudioService.GetStudioDoor(
        this.$store.getters["auth/userInfo"].company_branch_id
      ).then((res) => {
        if (res.IsSuccess === true || res.IsSuccess === 1) {
          this.studioDoorCreateModel = res.Result;
        }
      });
    },
    create() {
      this.isSubmit = true;
      if (this.$v.$invalid) return;
      this.$database.StudioService.StudioDoorCreate(
        this.studioDoorCreateModel
      ).then((res) => {
        if (!res.IsSuccess) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t(res.Result),
              icon: "XIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t(res.Result),
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$router.push("/home"); //sayfa yönlendirme
        }
      });
    },
  },
  watch: {},
  created() {
    // this.getCities();
    (this.studioDoorCreateModel.CompanyBranchId = this.$store.getters[
      "auth/userInfo"
    ].company_branch_id),
      this.getStudioDoor();

    // this.studioDoorCreateModel.CompanyBranchId= this.$route.params.companyBranchId;
    // this.studioDoorCreateModel.RoomId = this.$route.params.roomId;
    //console.log( this.studioDoorCreateModel.CompanyBranchId)
  },
};
</script>

<style lang="scss" src="@/styles/scss/studioCreate.scss" scoped></style>
